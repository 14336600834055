/* eslint-disable no-unused-vars */

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const routes = [
	{
		path: '/home',
		name: 'home',
		component: () => import('@/pages/Home.vue'),
	},
	{
		path: '/cryptique',
		name: 'cryptique',
		// component: () => import('@/components/ui/application/appcard/AppCard2.vue')
		component: () => import('@/pages/Cryptique.vue')
	},
	{
		path: '/macro-mate',
		name: 'macro-mate',
		// component: () => import('@/components/ui/application/appcard/AppCard2.vue')
		component: () => import('@/pages/MacroMate.vue')
	},
	{
		path: '/privacy-policy',
		name: 'privacy-policy',
		component: () => import('@/pages/PrivacyPolicy.vue')
	},
	{
		path: '*',
		redirect: {
			name: 'home',
		},
		// component: () => import('@/pages/Home.vue'),
		// children: [{
		// 	path: '',
		// 	name: 'error',
		// 	component: () => import('@/pages/error/NotFoundPage.vue')
		// }]
	}
];

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL || '/',
	scrollBehavior(to, from, savedPosition) {
		// console.log('scrollBehaviour', { to, from, savedPosition });
		return { x: 0, y: 0 };
	},
	routes
});

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
	return next();
});

/**
 * After each route update
 */
router.afterEach((to, from) => {
	// console.log('afterEachRouteUpdate', { to, from });
});

export default router;
