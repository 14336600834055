<template>
  <v-app>
    <app-toolbar>
      <router-view />
    </app-toolbar>
  </v-app>
</template>

<script>
import config from './configs';
import AppToolbar from './pages/AppToolbar.vue';

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
*/
export default {
	components: {
		AppToolbar,
	},

	head: {
		link: [
			// adds config/icons into the html head tag
			...config.icons.map((href) => ({ rel: 'stylesheet', href }))
		]
	}
};
</script>
