import icons from './icons';
import theme from './theme';
import analytics from './analytics';

export default {
	// product display information
	product: {
		name: 'Carbon UI',
		version: 'v1.2.0'
	},

	// icon libraries
	icons,

	// theme configs
	theme,

	// analytics configs
	analytics
};
